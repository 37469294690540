import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—May 2009" />
	<NewsHeader />
    <h2>May 2009</h2>


	<h3 className="blogdate">
	Wednesday, May 20, 2009
	</h3>

	<h4 className="blogitemtitle">
	Video upload: The Romance of Kaslo
	</h4>
		<div className="blogitembody">
		<p>I recently uploaded a video of me performing <i><span>The Romance of Kaslo</span></i>,
	live at The Paramount (April 16, 2009) in Wellington, New Zealand. If
	you're confused because my Paramount concert was on the 17th, that's
	okay... I also made an appearance at a private show on the 16th.</p>

	<p>
	I spent hours struggling with Cinelerra in Ubuntu to get this video edit
	to you, as well as mixing the audio in Ardour; it was fun! The video
	footage is courtesy of Kim Potter.
	</p>
	<p>
	Enjoy!
	</p>
	<p>
	Jesse
	</p>
		</div>
				<div className="blogitemfooter">
		<p>posted by Jesse @ 20:58 +1200</p>
		</div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
